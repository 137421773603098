<template>

  <div>
    <!-- Filters -->
    <!-- <Tansfers-list-filters
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
    /> -->

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="type the referency here and search "
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refTansferListTable"
        class="position-relative"
        :items="fetchTransfers"
        :fields="tableColumns"
        primary-key="_id"
        responsive
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        show-empty
        empty-text="No matching records found"
      >
        <!-- Column: Name -->
        <template #cell(amount)="data">
          <b-badge
            pill
            :variant="`light-success`"
            class="text-capitalize"
          >
            {{ data.item.amount }}
          </b-badge>        
        </template>

        <template #cell(validated)="data">
          <b-badge
            pill
            :variant="`light-${color[data.item.validated] || 'info'}`"
            class="text-capitalize"
          >
            {{ data.item.validated }}
          </b-badge>        
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="updateTransfersStatus(data.item._id, 'waiting',()=> $refs.refTansferListTable.refresh())">
              <feather-icon icon="FileTextIcon" color="orange"/>
              <span class="align-middle ml-50 " style="color:orange">STILL WHAITING</span>
            </b-dropdown-item>
            <div>
              <b-dropdown-item @click="updateTransfersStatus(data.item._id, 'validated',()=>$refs.refTansferListTable.refresh())">
                <feather-icon icon="EditIcon" color="green" />
                <span class="align-middle ml-50" style="color:green">VALIDATE</span>
              </b-dropdown-item>
            </div>
            <div>
              <b-dropdown-item @click="updateTransfersStatus(data.item._id, 'no validated',()=> $refs.refTansferListTable.refresh())">
                <feather-icon icon="EditIcon" color="red" />
                <span class="align-middle ml-50" style="color:red">UNVALIDATE</span>
              </b-dropdown-item>
            </div>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalTansfers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import TansfersListFilters from './TransfersListFilters.vue'
import useTansfersList from './useTransfersList'
import { methods } from 'vue-echarts'

export default {
  components: {
    TansfersListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  setup() {     
    const statusOptions = [
      { label: 'True', value: true },
      { label: 'False', value: false },
    ]
    const color = {
      validated: 'success',
      'no validated': 'danger',
      waiting: 'warning'
    }
    const {
      fetchTransfers,
      updateTransfersStatus,
      tableColumns,
      perPage,
      currentPage,
      totalTansfers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTansferListTable,
      refetchData,
      recordDeleted,
      // UI   
      resolveTansferStatusVariant,
      // Extra Filters
      statusFilter,
    } = useTansfersList()
  
    return {
      // Sidebar
      tableColumns,
      fetchTransfers,
      updateTransfersStatus,
      perPage,
      currentPage,
      totalTansfers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTansferListTable,
      refetchData,
      recordDeleted,
      //UI
      resolveTansferStatusVariant,
      statusOptions,
      // Extra Filters  
      statusFilter,
      color
    }
  },
  watch:{
    currentPage(){
      this.$refs.refTansferListTable.refresh()
    }, 
    perPage(){
      this.$refs.refTansferListTable.refresh()
    }, 
    searchQuery(){
      this.$refs.refTansferListTable.refresh()
    },
    recordDeleted(){
      this.$refs.refTansferListTable.refresh()
    }
  },
  methods: {
    updateQuery(){
      this.$refs.refSearchQuery
      //this.searchQuery = this.$refs.refSearchQuery.value
      console.log(this.$refs.refSearchQuery)
    }
  }
  /*
  computed: {
    canDelete() {
      var actions = this.roleActions()
      if (actions) {
        return actions.delete
      }
    },
    canCreate() {
      var actions = this.roleActions()
      if (actions) {
        return actions.create
      }
    },
    canEdit() {
      var actions = this.roleActions()
      if (actions) {
        return actions.update
      }
    },
  },
  methods: {
    roleActions() {
     const permissions = JSON.parse(sessionStorage.getItem('permissions')) 
      var actions = permissions.find(permission => permission.path == this.$route.path)
      return actions
    },
  }
  */
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.firstletter::first-letter{
  color: #efb810 !important;
  font-size:2em;
	font-family:serif;
  text-transform: capitalize;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>