import { ref, watch, computed } from '@vue/composition-api'
import axios from '@/libs/axios'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useTransfersList() {
  // Use toast
  const toast = useToast()
  const refTransferListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'referency', sortable: true },
    { key: 'bank', sortable: true },
    { key: 'bankDestiny', sortable: true },
    { key: 'amount' },
    { key: 'validated' },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalTransfers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const recordDeleted = ref(false)
  
  const dataMeta = computed(() => {
    const localItemsCount = refTransferListTable.value ? refTransferListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTransfers.value,
    }
  })
  const refetchData = () => {
    console.log(refTransferListTable)
    if (refTransferListTable.value){
      refTransferListTable.value.refresh()
    }
  }

  const fetchTransfers = (ctx, callback) => {
    axios.get(`/transactions?q=${searchQuery.value || ''}&perPage=${perPage.value || ''}&page=${currentPage.value || ''}&sortBy=${sortBy.value || ''}&sortDesc=${isSortDirDesc.value || ''}`)
    .then((response) => {
      const { transfers, total } = response.data
      callback(transfers)
      totalTransfers.value = total
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Transfers list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }
  const updateTransfersStatus = (id,newStatus,callback) =>{
    axios.put(`/transactions/${id}`,{ validated: newStatus })
    .then((response) => {
      callback()
      toast({
        component: ToastificationContent,
        props: {
          title: 'Transaction updated',
          icon: 'AlertTriangleIcon',
          variant: 'success',
        },
      })
    })
    .catch(error => {
      console.log(error)
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error updating Transfers list',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
  }
  


  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchTransfers,
    updateTransfersStatus,
    tableColumns,
    perPage,
    currentPage,
    refTransferListTable,
    totalTransfers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refetchData,
    recordDeleted
  }
}
