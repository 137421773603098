import { render, staticRenderFns } from "./TransfersListFilters.vue?vue&type=template&id=574c0f65"
import script from "./TransfersListFilters.vue?vue&type=script&lang=js"
export * from "./TransfersListFilters.vue?vue&type=script&lang=js"
import style0 from "./TransfersListFilters.vue?vue&type=style&index=0&id=574c0f65&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports